.change-pas {
    width: 100%;
    box-sizing: border-box;
    padding: 0rem 2rem;
    padding-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__button {
      margin-top: 5rem;
      width: 80%;
      height: 3rem;
      background: #008161;
      box-shadow: 0px 0px 20px rgba(0, 178, 255, 0.28);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      color: #fff;
      cursor: pointer;
    }
  }
  