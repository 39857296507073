.users-list {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
  &__header {
    display: flex;
    align-items: center;
    margin-top: 1px;
    height: 2.4286rem;
    font-size: 1rem;
    padding-left: 0.8rem;
    justify-content: center;
    font-weight: bold;
  }
  &__filter-block {
    padding: 0.7rem;
    position: relative;
    width: 87%;
    align-self: center;
    margin-bottom: 2.5rem;
    justify-content: center;
    display: flex;
  }
  &__search-icon {
    position: absolute;
    left: 2.2rem;
    top: calc(50% - 0.5rem);
    &-svg {
      width: 1rem;
    }
  }

  &__subheader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 1.25rem;
    &-text, &-count {
      width: 100%;
      height: 1.4286rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8571rem;
      padding: 0 0.9rem;
      box-sizing: border-box;
    }
    &-text {
      color: #fff;
      background: #585858;
    }
  }
  &__bold-text {
    font-weight: bold;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    height: 2.5625rem;
    padding: 1.5rem;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #E0E0E0;
    margin-bottom: 4px;
    &-nick {
      color: #000;
    }
    &-balance {
      margin-right: 0.7rem;
      font-weight: bold;

    }
    &:nth-child(2n) {
      background: #f8f8f8;
    }
    &-right {
      display: flex;
      align-items: center;
      color: #000;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      width: 1.0714rem;
      height: 1.0714rem;
    }
  }

  &__list {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  &__user-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    height: 2.5625rem;
    padding: .5rem;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #E0E0E0;
    width: 50%;
    margin-right: 1rem;
    &-nick {
      color: #000;
    }
    &-balance {
      margin-right: 0.7rem;
      font-weight: bold;

    }
    &:nth-child(2n) {
      background: #f8f8f8;
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__account-balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    height: 2.5625rem;
    padding: .5rem;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #E0E0E0;
    width: 50%;
    &-nick {
      color: #000;
    }
    &-balance {
      margin-right: 0.7rem;
      font-weight: bold;

    }
    &:nth-child(2n) {
      background: #f8f8f8;
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }

}